import React, { Fragment } from 'react'
import classes from './Projects.module.css';
import NavBar from '../Layout/NavBar/NavBar';
import Footer from '../Layout/Footer/Footer';

const Projects = () => {
    return (
        <Fragment>
            <NavBar />
            <div className={classes.GlobalOne} style={{ marginTop: '60px' }}>
                <a href="http://www.bit.ly/3e3OY7b" target="_blank" rel="noopener noreferrer"><h2>Pomodoro Tracker</h2></a>
                <p className={classes.ParStyle}>
                    App inspired by the Pomodoro technique, a time management method. Users can utilize the timer provided to go through the Pomodoro cycle, and each time a Pomodoro is complete, the state managed counter is updated. Users can create an account, and save & view the progress for each day on the tracker page.<br /><br />
                    Tech utilized: React.js (hooks and router), Redux, Bootstrap,  Django, Django Rest Framework, Knox token authentication, PostgreSQL database, and the frontend was deployed via Firebase & the backend deployed via Heroku.
                </p>

            </div>
            <div className={classes.GlobalTwo}>
                <a href="http://bit.ly/3bQGMpF" target="_blank" rel="noopener noreferrer"><h2>Weather Forecaster</h2></a>
                <p className={classes.ParStyleTwo}>
                    This app shows current and forecasted weather for a zip code entered by a user. Utilizes the OpenWeatherMap API to generate the weather data.<br /><br />
                    Tech utilized: HTML, CSS, Bootstrap, Django, and was deployed via Heroku.
                </p>
            </div>
            <div className={classes.GlobalOne}>
                <a href="http://bit.ly/2WR8nmd" target="_blank" rel="noopener noreferrer"><h2>Deals Web Scraper</h2></a>
                <p className={classes.ParStyle}>
                    Web scraper that sends an email when items from a user’s favorite site are at or below the price point they  are willing to pay.<br /><br />
                    Tech utilized:
                    Python, requests library to make requests to the website, BeautifulSoup to parse HTML, and smtplib module to send an email to the user.
                </p>
            </div>
            <div className={classes.GlobalTwo} style={{ marginBottom: '100px' }}>
                <a href="http://francisconavarro.dev/" target="_blank" rel="noopener noreferrer"><h2>My Personal Website</h2></a>
                <p className={classes.ParStyleTwo}>
                    The website that you are currently on :)<br /><br />
                    Tech utilized: React.js, JavaScript, HTML, CSS, and deployed via Firebase.
                </p>

            </div>
            <Footer />
        </Fragment >
    )
}

export default Projects;
