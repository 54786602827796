import React from 'react';
import NavBar from './NavBar/NavBar';
import Welcome from '../Layout/Welcome/Welcome';


const Layout = () => {
    return (
        <>
            <NavBar />
            <Welcome />
        </>
    )
}

export default Layout;
