/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';

import NavBar from '../Layout/NavBar/NavBar';
import classes from './Resume.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Resume = () => {

    function useWindowSize() {
        const isClient = typeof window === 'object';

        function getSize() {
            return {
                width: isClient ? window.innerWidth : undefined,
                height: isClient ? window.innerHeight : undefined
            };
        }

        const [windowSize, setWindowSize] = useState(getSize);

        useEffect(() => {
            if (!isClient) {
                return false;
            }

            function handleResize() {
                setWindowSize(getSize());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [getSize, isClient]);

        return windowSize;
    }
    const size = useWindowSize();

    function widthPage() {
        if (size.width > 830) {
            return (<Page width='800' pageNumber={1} />);
        } else if (size.width > 650) {
            return (<Page width='600' pageNumber={1} />)
        } else {
            return (<Page width='300' pageNumber={1} />)
        };
    }

    return (
        <Fragment>
            <NavBar />
            <div className={classes.Global}>
                <a className={classes.Download} href={'francisco_navarro.pdf'} download>Download Resume</a>
                <Document file={'francisco_navarro.pdf'}>
                    {widthPage()}
                </Document>
            </div>
        </Fragment >

    )
}

export default Resume;
