import React, { Fragment } from 'react';
import IMG from '../../../assets/portfolio.jpg';
import classes from './Welcome.module.css';
import Footer from '../Footer/Footer';

const Welcome = () => {
    return (
        <Fragment>
            <div className={classes.Global}>
                <img src={IMG} alt="francisco navarro" />
                <div className={classes.Text}>
                    <h4>A bit about me</h4>
                    <p style={{ textIndent: '50px' }}>Hello! I'm Francisco Navarro, aspiring Software Engineer, problem solver, and YouTube addict (a lot of learning videos... I swear). Coding has been a part of my life (on and off) for the last 7 years. Back in 2014, I had the opportunity to help out in my community which gave me the chance to build out my very first website for a local church. Since then I knew that I had found a new passion.</p>
                </div>
            </div>
            <Footer />
        </Fragment >

    )
}

export default Welcome;


