import React from 'react';
import classes from './Footer.module.css';
import { SocialIcon } from 'react-social-icons';


const Footer = () => {
    return (

        <div className={classes.FooterTitle}>
            <h3>Let's Connect</h3>
            <div className={classes.Footer}>
                <SocialIcon url="mailto:fnavarro1612@gmail.com" network="email" bgColor="#2D5F5D" fgColor="white"
                    style={{ height: 40, width: 40 }} className="SocialIcon" target="_blank" rel="noopener noreferrer" />
                <SocialIcon url="https://www.linkedin.com/in/francisco-navarro-a25868b3/" bgColor="#2D5F5D" fgColor="white"
                    style={{ height: 40, width: 40 }} className="SocialIcon" target="_blank" rel="noopener noreferrer" />
                <SocialIcon url="https://github.com/fnavarro1612" bgColor="#2D5F5D" fgColor="white" className="SocialIcon"
                    style={{ height: 40, width: 40 }} target="_blank" rel="noopener noreferrer" />
                <SocialIcon url="https://twitter.com/cisconavarroo" bgColor="#2D5F5D" fgColor="white"
                    style={{ height: 40, width: 40 }} className="SocialIcon" target="_blank" rel="noopener noreferrer" />
            </div>
        </div>
    )
}

export default Footer;
