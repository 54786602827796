import React, { Fragment } from 'react'
import classes from './Contact.module.css';
import NavBar from '../Layout/NavBar/NavBar';
import Footer from '../Layout/Footer/Footer';

const Contact = () => {
    return (
        <Fragment>
            <NavBar />
            <div className={classes.Global}>
                <Footer />
                <h3> <a href="mailto:fnavarro1612@gmail.com">fnavarro1612 at gmail.com</a></h3>
            </div>

        </Fragment>
    )
}

export default Contact;
