import React, { Fragment } from 'react'
import classes from './Learning.module.css';
import NavBar from '../Layout/NavBar/NavBar';
import Footer from '../Layout/Footer/Footer';

const Learning = () => {
    return (
        <Fragment>
            <NavBar />

            <p className={classes.Heading}>Some of my favorite learning resources</p>

            <div className={classes.Global}>
                <div>
                    <p>Data Structures and Algorithms</p>
                    <ul>
                        <a href="https://www.amazon.com/dp/0262033844/ref=sr_1_2?crid=3UAOBUUXL11DX&dchild=1&keywords=algorithms+cormen&qid=1590515345&sprefix=algortihms+c%2Caps%2C221&sr=8-2" target="_blank" rel="noopener noreferrer"><li>Introduction to Algorithms CLRS (book)</li></a>
                        <a href="https://www.algoexpert.io/product" target="_blank" rel="noopener noreferrer"><li>AlgoExpert (platform)</li></a>
                        <a href="https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-006-introduction-to-algorithms-fall-2011/" target="_blank" rel="noopener noreferrer"><li>Introduction to Algorithms (MIT course)</li></a>
                    </ul>
                </div>
                <div>
                    <p>Python</p>
                    <ul>
                        <a href="https://www.amazon.com/Python-Programming-Introduction-Computer-Science/dp/1590282752/ref=sr_1_7?dchild=1&keywords=intro+to+python&qid=1590515560&sr=8-7" target="_blank" rel="noopener noreferrer"><li>Python Programming (book)</li></a>
                        <a href="https://www.amazon.com/Fluent-Python-Concise-Effective-Programming/dp/1491946008/ref=sr_1_1?crid=3K5XQ5XGOUQN5&dchild=1&keywords=fluent+python&qid=1590515540&sprefix=fluent+p%2Caps%2C228&sr=8-1" target="_blank" rel="noopener noreferrer"><li>Fluent Python (book)</li></a>
                        <a href="https://www.udemy.com/course/django-python-advanced/" target="_blank" rel="noopener noreferrer"><li>Build a Backend REST API with Python & Django (course)</li></a>
                        <a href="https://www.udemy.com/course/the-python-mega-course/" target="_blank" rel="noopener noreferrer"><li>The Python Mega Course (course)</li></a>
                    </ul>
                </div>
                <div>
                    <p>JavaScript</p>
                    <ul>
                        <a href="https://www.amazon.com/Eloquent-JavaScript-3rd-Introduction-Programming/dp/1593279507/ref=sr_1_1?crid=156YD1YXH679D&dchild=1&keywords=eloquent+javascript&qid=1590515509&sprefix=eloquent%2Caps%2C222&sr=8-1" target="_blank" rel="noopener noreferrer"><li>Eloquent JavaScript (book)</li></a>
                        <a href="https://www.udemy.com/course/react-the-complete-guide-incl-redux/" target="_blank" rel="noopener noreferrer"><li>React - The Complete Guide (course)</li></a>
                        <a href="https://www.udemy.com/course/modern-javascript-from-the-beginning/" target="_blank" rel="noopener noreferrer"><li>Modern JavaScript from the beginning (course)</li></a>
                    </ul>
                </div>

            </div>
            <Footer />


        </Fragment >
    )
}

export default Learning;
