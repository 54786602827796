import React, { useState } from 'react';
import classes from './NavBar.module.css';
import { Link } from 'react-router-dom';

const NavBar = () => {

    const [toggle, setToggle] = useState(false);

    let attachedClasses = [classes.NavLinks];
    let burgerClasses = [classes.Burger];
    const toggleNav = () => {
        setToggle(!toggle);
    };

    if (toggle) {
        attachedClasses.push(classes.NavActive);
        burgerClasses.push(classes.Toggle);
    }

    return (
        <nav className={classes.Nav}>
            <div className={classes.Logo}>
                <Link to='/'><h4>Francisco Navarro</h4></Link>
            </div>
            <ul className={attachedClasses.join(' ')}>
                <li><Link to='/projects'>Projects</Link></li>
                <li><Link to='/resume'>Resume</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
                <li><Link to='/learning'>Learning Resources</Link></li>
            </ul>
            <div onClick={toggleNav} className={burgerClasses.join(' ')}>
                <div className={classes.Line1}></div>
                <div className={classes.Line2}></div>
                <div className={classes.Line3}></div>
            </div>
        </nav >
    )
}

export default NavBar;
