import React from 'react';
import classes from './App.module.css';
import Layout from './components/Layout/Layout';
import { Switch, Route } from 'react-router-dom';
import Resume from './components/Resume/Resume';
import Contact from './components/Contact/Contact';
import Projects from './components/Projects/Projects';
import Learning from './components/Learning/Learning';


function App() {
  return (
    <div className={classes.App}>
      <Switch>
        <Route path='/' exact component={Layout} />
        <Route path='/resume' component={Resume} />
        <Route path='/contact' component={Contact} />
        <Route path='/projects' component={Projects} />
        <Route path='/learning' component={Learning} />
      </Switch>
    </div>
  );
}

export default App;
